import $2 from "jquery";
window.$2 = $2;
import 'simplebar/dist/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

"use strict";

let lang = 'pl';

$(function() {
    lang = $('html').attr('lang');

    if ($('#modal-after').length) {
        $('#modal-after').modal('show');
    }

    const sections = document.querySelectorAll("[data-id='scrollspy'] section");
    const navLinks = document.querySelectorAll("[data-id='scrollspy-nav'] a");
    const nav = document.querySelector("[data-id='scrollspy-nav']");
    const lastElHeight = sections[sections.length - 1].getBoundingClientRect().height + 200;

    window.onscroll = (el) => {
        sections.forEach((section) => {
            let top = window.scrollY;
            let navHeight = nav.offsetHeight;
            let offset = section.offsetTop - navHeight;
            let height = section.offsetHeight;
            let id = section.getAttribute('id');

            if (top >= offset && top < offset + height) {
                navLinks.forEach((links) => {
                    links.classList.remove('active');
                    document.querySelector(`nav a[href*="${id}"]`)?.classList.add('active');
                });
            }

            if (
                sections[sections.length - 1].getBoundingClientRect().top < lastElHeight
            ) {
                navLinks.forEach((links) => {
                    links.classList.remove('active');
                    document.querySelector(`nav a[href*="${id}"]`)?.classList.add('active');
                });
            }
        });
    };

    $('.logo-slider').each(function () {
        new Swiper(this, {
            modules: [Autoplay],
            spaceBetween: 0,
            loop: true,
            speed: 5000,
            autoplay: {
                delay: 0,
            },
            freeMode: true,
            slidesPerView: "auto",
            allowTouchMove: false,
            breakpoints: {
                768: {
                    spaceBetween: 48,
                },
            },
        });
    });

    let sProductsSlider;

    sProductsSlider = new Swiper($('.s-products__slider .swiper')[0], {
        slidesPerView: 1,
        loop: true,
        touchRatio: 0.1,
        centeredSlides: true,
        breakpoints: {
            992: {
                slidesPerView: 3,
            }
        },
        navigation: {
            prevEl: $('.s-products__slider .swiper').siblings('.swiper-button-prev')[0],
            nextEl: $('.s-products__slider .swiper').siblings('.swiper-button-next')[0]
        },
        modules: [Navigation]
    });

    let sStagesSlider;

    sStagesSlider = new Swiper($('.s-winners__slider .swiper')[0], {
        slidesPerView: 2,
        loop: false,
        touchRatio: 0.1,
        breakpoints: {
            768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
            }
        },
        pagination: {
            el: $('.s-winners__slider .swiper').siblings('.swiper-pagination')[0],
            clickable: true
        },
        navigation: {
            prevEl: $('.s-winners__slider .swiper').siblings('.swiper-button-prev')[0],
            nextEl: $('.s-winners__slider .swiper').siblings('.swiper-button-next')[0]
        },
        modules: [Navigation, Pagination],
        on: {
            click() {
                sStagesSlider.slideTo(this.clickedIndex);
                $('.s-winners__slider .swiper').find('.swiper-slide button').removeClass('active');
                $($('.s-winners__slider .swiper').find('.swiper-slide')[this.clickedIndex]).find('button').addClass('active');

                const prize = '#energylandia';
                const week = $($('.s-winners__slider .swiper').find('.swiper-slide')[this.clickedIndex]).attr('data-target');

                $(prize).find('.s-winners__content-1').addClass('d-none');
                $(prize).find(week).removeClass('d-none');
            },
        },
    });

    $('.slider').each(function () {
        const slidesToShow = $(this).data('slides-to-show');
        const arrows = $(this).data('arrows');
        const dots = $(this).data('dots');

        $(this).slick({
            slidesToShow: slidesToShow,
            arrows: arrows,
            dots: dots,
            draggable: $(this).closest('#workpage').length === 0
        });
    });

    $('div[data-news="true"]').each(function () {
        const self = this;
        const url = '/category/news';
        let data = {
            category: $(self).data('categoryid'),
        };

        if ($('#workpage').length === 0) {
            data.lang = lang;
        }

        $.ajax({
            url: url,
            type: 'GET',
            data: data,
            complete: function (response) {
                const news = response.responseJSON;

                $(self).find('.news-item').each(function (key) {
                    if (news[key]) {
                        $($(this).find('.photo')).css('background-image', `url(${news[key].image})`);
                        $($(this).find('.details h5')).text(news[key].title);
                        $($(this).find('.details h4')).text(news[key].lead);
                        $(this).removeClass('d-none');

                        $(this).removeClass('loading-skeleton');
                    }
                });
            }
        });
    });

    $('.contact-section .btn-submit').on('click', function (e) {
        e.preventDefault();

        const form = $(this).closest('form');
        const formData = form.serializeArray();
        const agree = formData.find(function (item) { return item.name === 'agree' });

        if (agree) {
            agree.value = $('input[name="agree"]').is(':checked')
        } else {
            formData.push({
                name: 'agree',
                value: $('input[name="agree"]').is(':checked')
            });
        }

        if (checkFormValidation(formData)) {
            formData.push(
                {
                    name: 'subject',
                    value: `Kontakt ze strony: ${window.location.href}`
                },
                {
                    name: 'sendTo',
                    value: form.attr('data-email')
                });

            $.ajax({
                url: form.attr('action'),
                type: 'POST',
                data: formData,
                complete: function (response) {
                    if (response.responseJSON.status === 'success') {
                        $(form).prepend('<div class="message-was-sent">Dziękujemy, wiadomość została wysłana.</div>');
                    } else {
                        $(form).prepend('<div class="message-was-not-sent">Przepraszamy. wiadomość nie została wysłana</div>');
                    }

                    formData.forEach(function (field) {
                        if ($(`form [name="${field.name}"]`)) {
                            $(`form [name="${field.name}"]`).removeClass('is-valid');
                            $(`form [name="${field.name}"]`).removeClass('is-invalid');

                            if (response.responseJSON.status === 'success') {
                                if ($(`form [name="${field.name}"]`).attr('type') === 'checkbox') {
                                    $(`form [name="${field.name}"]`).prop('checked', false);
                                } else {
                                    $(`form [name="${field.name}"]`).val('');
                                }
                            }
                        }
                    });
                }
            });
        }
    });
});

function checkFormValidation(fields) {
    let isValid = true;
    const regexErrors = {
        pl: {
            email: 'Nieprawidłowy adres e-mail',
            email2: 'Nieprawidłowy adres e-mail',
            telefon: 'Numer telefonu powinien składać się z {range} cyfr',
            imie: 'Imie powinno się składać z samych liter',
            nazwisko: 'Nazwisko powinno się składać z samych liter',
            numberRange: 'Wartość powinna mieścić się w zakresie '
        },
        en: {
            email: 'Incorrect e-mail address',
            email2: 'Incorrect e-mail address',
            telefon: 'The telephone number should consist of {range} digits',
            imie: 'First name should consist of letters',
            nazwisko: 'Surname should consist of letters',
            numberRange: 'The value should be in the range '
        },
        de: {
            email: 'Falsche E-Mail Adresse',
            email2: 'Falsche E-Mail Adresse',
            telefon: 'Die Telefonnummer sollte aus {range} Ziffern bestehen',
            imie: 'Der Vorname sollte aus Buchstaben bestehen',
            nazwisko: 'Der Nachname sollte aus Buchstaben bestehen',
            numberRange: 'Der Wert sollte im Bereich liegen '
        }
    }

    const requiredErrors = {
        pl: 'To pole jest wymagane',
        en: 'This field is required',
        de: 'Dieses Feld ist erforderlich'
    }

    fields.forEach(function (field) {
        const pattern = $('form').find(`[name="${field.name}"]`).attr('pattern');
        let validationMessage = '';

        if (pattern) {
            const regex = new RegExp(pattern);

            if (regex.test(field.value)) {
                $(`form [name="${field.name}"]`).removeClass('is-invalid');
                $(`form [name="${field.name}"]`).addClass('is-valid');
                validationMessage = '';
            } else {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                if (field.name === 'telefon') {
                    const range = $(`form [name="${field.name}"]`).attr('data-range') || '9';
                    validationMessage = regexErrors[lang][field.name].replace('{range}', range);
                } else {
                    validationMessage = regexErrors[lang][field.name];
                }
            }
        } else {
            if (!field.value && $(`form [name="${field.name}"]`).get(0).hasAttribute('required')) {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                validationMessage= requiredErrors[lang];
            } else if (field.value && $(`form [name="${field.name}"]`).get(0).hasAttribute('min')
                && (parseInt($(`form [name="${field.name}"]`).attr('min'), 10) > parseInt(field.value, 10)
                    || parseInt($(`form [name="${field.name}"]`).attr('max'), 10) < parseInt(field.value, 10))
                && $(`form [name="${field.name}"]`).get(0).hasAttribute('max')) {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                validationMessage= `${regexErrors[lang]['numberRange']}${$(`form [name="${field.name}"]`).attr('min')} - ${$(`form [name="${field.name}"]`).attr('max')}`;
            } else {
                $(`form [name="${field.name}"]`).removeClass('is-invalid');
                $(`form [name="${field.name}"]`).addClass('is-valid');
                validationMessage = '';
            }
        }

        $(`form [name="${field.name}"]`).closest('.form-group').find('.validation-error').text(validationMessage);
    });

    return isValid;
}
